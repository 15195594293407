import '@/css/app.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

import 'simple-lightbox/dist/simpleLightbox.min.css'

import { initializeKinesis } from "@amineyarman/kinesis"
import { init as CookieInit } from 'cookie-though'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
Alpine.plugin([focus, collapse])

import { CurrentOrder } from './global.model'

//import { mountVueApp } from '@/vue/app.ts';
//import { mountReactApp } from '../react/app'; //'@/react/app.tsx';

const main = async () => {
    document.addEventListener("DOMContentLoaded", () => {
        initializeKinesis();
    })

    cookieThough()
    newsletter()

    // Lazy-load modules based on selectors
    const dynamicComponents = [
        { selector: '.gsapBatchUpBase', method: 'gsapBatchUpBase' },
        { selector: '.swiperDefault', method: 'swiperDefault' },
        { selector: '.swiperGallery', method: 'swiperGallery' },
        { selector: '.lightBoxGallery', method: 'simpleLightbox' },
    ];
    dynamicComponents.forEach(({ selector, method }) => {
        if (document.querySelector(selector)) {
            import('./components/defaults').then(module => module[method]()).catch(error => console.error(`Error loading ${method}:`, error));
        }
    });

    // Vue / React
    //mountVueApp();
    //mountReactApp()

    // Alpine.js initialization
    document.addEventListener('alpine:init', () => {
        // Create a URL object to parse the query string of the url
        const params = new URLSearchParams(new URL(window.location.href).search);

        const partner  = params.get('partner') ?? null;
        const location = params.get('location') ?? null;
        if (partner || location) {
            console.info('alpine:init :: URLSearchParams partner', partner, 'URLSearchParams location', location)
        }

        const machineType = document.getElementById('locationsAlpine') ? document.getElementById('locationsAlpine')!.dataset?.machinetype : null

        let order = null
        const jsOrderData = document.getElementById('js-order-data');
        if (jsOrderData) {
            order = JSON.parse(jsOrderData.textContent || null);
            if (order) {
                //console.log('order (php session value via script tag)', order[0] ?? null, 'orderId', order[0]?.id ?? null)
            }
        }

        Alpine.store('currentOrder', {
            message: null,
            reference: null,
            remark: null,

            machineType: machineType ?? null,
            machineId: null,

            selectedDates: [],

            partner: partner,
            partnerObj: [],

            location: location,
            locationObj: [],

            products: [],

            orderId: order ? order[0]?.id : null,
            order: order ? order[0] : null,

            hasAssurance: false,

            reset() {
                const currentOrder = Alpine.store('currentOrder') as CurrentOrder

                if (currentOrder) {
                    currentOrder.message = null
                    currentOrder.reference = null
                    currentOrder.remark = null
                    currentOrder.machineType = machineType
                    currentOrder.selectedDates = []
                    currentOrder.partner = null
                    currentOrder.partnerObj = []
                    currentOrder.location = null
                    currentOrder.locationObj = []
                    currentOrder.products = []
                    currentOrder.orderId = null
                    currentOrder.order = null
                    currentOrder.hasAssurance = false

                    console.warn('currentOrder reset', currentOrder)
                } else {
                    console.error('currentOrder is not defined (reset)')
                }
            },
        } as CurrentOrder)

        // Load existing data from localStorage (if any)
        const savedOrder: CurrentOrder = JSON.parse(localStorage.getItem('currentOrder') || '{}')
        if (savedOrder) {
            const currentOrder = Alpine.store('currentOrder') as CurrentOrder

            currentOrder.message = savedOrder.message ?? currentOrder.message
            currentOrder.reference = savedOrder.reference ?? currentOrder.reference
            currentOrder.remark = savedOrder.remark ?? currentOrder.remark
            currentOrder.machineType = savedOrder.machineType ?? currentOrder.machineType
            currentOrder.machineId = savedOrder.machineId ?? currentOrder.machineId
            currentOrder.selectedDates = savedOrder.selectedDates ?? currentOrder.selectedDates
            currentOrder.partner = savedOrder.partner ?? currentOrder.partner
            currentOrder.partnerObj = savedOrder.partnerObj ?? currentOrder.partnerObj
            currentOrder.location = savedOrder.location ?? currentOrder.location
            currentOrder.locationObj = savedOrder.locationObj ?? currentOrder.locationObj
            currentOrder.products = savedOrder.products ?? currentOrder.products
            currentOrder.orderId = savedOrder.orderId ?? currentOrder.orderId
            currentOrder.order = savedOrder.order ?? currentOrder.order
            currentOrder.hasAssurance = savedOrder.hasAssurance ?? currentOrder.hasAssurance

            //console.warn('currentOrder from localStorage', currentOrder)
        }

        // Watch for changes and save to localStorage
        Alpine.effect(() => {
            const currentOrder = Alpine.store('currentOrder') as CurrentOrder

            const dataToStore = {
                message: currentOrder.message,
                reference: currentOrder.reference,
                remark: currentOrder.remark,
                machineType: currentOrder.machineType,
                machineId: currentOrder.machineId,
                selectedDates: currentOrder.selectedDates,
                partner: currentOrder.partner,
                partnerObj: currentOrder.partnerObj,
                location: currentOrder.location,
                locationObj: currentOrder.locationObj,
                products: currentOrder.products,
                orderId: currentOrder.orderId,
                order: currentOrder.order,
                hasAssurance: currentOrder.hasAssurance,
            };
            localStorage.setItem('currentOrder', JSON.stringify(dataToStore));
            //console.warn('Alpine.effect > Saved currentOrder to localStorage:', dataToStore);
        });

        Alpine.data('toastSystem', () => ({
            toastsList: [],

            init() {
                // Fetch flash messages from the DOM
                const flashMessages = JSON.parse(document.getElementById('flash-data').textContent || '{}');
                //console.log('Flash messages:', flashMessages);

                // Add flash messages to the toast list
                Object.keys(flashMessages).forEach((type) => {
                    if (type === 'error' || type === 'warning' || type === 'success') {
                        const messages = Array.isArray(flashMessages[type]) ? flashMessages[type] : [flashMessages[type]];

                        messages.forEach((message) => {
                            this.addToast(type, message);
                        });
                    }
                });

                // Listen for custom toast events
                window.addEventListener('toast', (event) => {
                    //console.log('addEventListener > Received toast event:', event.detail);
                    const { type, message } = event?.detail;
                    this.addToast(type, message);
                });

                //this.addToast('success', 'Toasts are working!');
            },

            addToast(type: string, message: string) {
                // Add a toast with a unique ID for tracking
                const id = Date.now() + Math.random();
                this.toastsList.push({ id, type, message });

                // Automatically remove the toast after 5 seconds
                /*setTimeout(() => {
                    this.removeToast(id);
                }, 50000); // Auto-remove after 5 seconds*/
            },

            removeToast(id: number) {
                this.toastsList = this.toastsList.filter((toast) => toast.id !== id);
            },
        }));
    });

    const promises = [];

    const blocks = ['testimonials', 'spotlight'];

    for (const block of blocks) {
        const blockElements = document.querySelectorAll(`.${block}`);

        const { init } = await import(`./blocks/${block}.ts`);

        blockElements.forEach((block) => {
            promises.push(init(block));
        });
    }

    const components = ['navMobile', 'js-customLocationsList', 'js-customOrderDashboard', 'js-customOrderProducts', 'js-customOrderSummary'];

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`);

        const { init } = await import(`./components/${comp.replace('js-','')}.ts`);

        compElements.forEach((comp) => {
            promises.push(init(comp));
        });
    }

    await Promise.all(promises).catch((e) => {
        console.error(e.message, e);
    });
};

export const triggerToast = (type, message) => {
    //console.log('triggerToast > Triggering toast:', type, message);
    window.dispatchEvent(new CustomEvent('toast', { detail: { type, message } }));
};

const cookieThough = () => {
    setTimeout(() => {
        CookieInit(
            window.translations?.cookieThough
            /*{
            "policies": [
                {
                    "id": "essential",
                    "label": "Essential Cookies",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                    "category": "essential",
                },
                {
                    "id": "functional",
                    "label": "Functional Cookies",
                    "category": "functional",
                    "description": "We need to save some basic preferences eg. language.",
                },
                {
                    "id": "statistics",
                    "label": "Statistics",
                    "category": "statistics",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                },
                {
                    "id": "social",
                    "label": "Social Media Cookies",
                    "category": "social",
                    "description": "We need to save some social cookies, for the website to function properly.",
                },
            ],
            "essentialLabel": "Always on",
            "permissionLabels": {
                "accept": "Accept",
                "acceptAll": "Accept all",
                "decline": "Decline"
            },
            "cookiePreferenceKey": "cookie-preferences",
            "header": {
                "title": "cookie though?",
                "subTitle": "You're probably fed up with these banners...",
                "description": "Everybody wants to show his best side - and so do we. That’s why we use cookies to guarantee you a better experience."
            },
            "cookiePolicy": {
                "url":"https://inthepocket.com/cookie-policy",
                "label":"Read the full cookie declaration",
            },
            "customizeLabel": "Customize"
        }*/);
    }, 1000);

    /*document.getElementById("re-consent").addEventListener("click", () => {
      CookieThough.show();
    });*/
};

const newsletter = () => {
    const newsletterButton = document.querySelector('a[href="#newsletter"]');

    if (newsletterButton) {
        newsletterButton.addEventListener('click', triggerNewsletterOpen);
    }

    function triggerNewsletterOpen() {
        document.getElementById('newsletter-button').click();
    }
}

main().then(() => {
    console.info('Vite <3');
    Alpine.start();
});